.gradient_background {
	background-image: radial-gradient(at top left, #4589df 30%, transparent 80%),
		radial-gradient(at bottom, #4589df 0%, transparent 60%),
		radial-gradient(at bottom left, #4589df 0%, transparent 50%),
		radial-gradient(at top right, #4589df, transparent),
		radial-gradient(at bottom right, #4589df 0%, transparent 50%);
	background-blend-mode: saturation;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 0;
	opacity: 0.5;
	height: 100vh;
	width: 100%;
	overflow: hidden;
}

.background_fade {
	background-image: linear-gradient(0deg, #171a1d 60%, rgba(0, 0, 0, 0) 100%);
	position: absolute;
	inset: 0px;
	z-index: 0;
}

.light_background_fade {
	background-image: linear-gradient(0deg, white 60%, rgba(0, 0, 0, 0) 100%);
	position: absolute;
	inset: 0px;
	z-index: 0;
}
